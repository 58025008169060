var render = function () {
  var _vm$approveData, _vm$approveData2, _this$approveData, _this$approveData2, _this$approveData3, _this$approveData4, _vm$approveData5, _vm$approveData6, _vm$approveData7, _vm$approveData8, _vm$approveData9, _vm$approveData10, _vm$approveData11, _vm$approveData12, _vm$approveData13, _vm$approveData14;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.$permissionAbility(_vm.SITE_LIST_SHOW, _vm.permissions) ? _c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('div', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1",
    attrs: {
      "placeholder": "Search Website",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "niche",
      "options": _vm.filteredNicheOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "placeholder": "Select Niche"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterSelectNicheId,
      callback: function callback($$v) {
        _vm.filterSelectNicheId = $$v;
      },
      expression: "filterSelectNicheId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "seller",
      "options": _vm.filteredSellerOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "placeholder": "Select Seller"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterSelectSellersId,
      callback: function callback($$v) {
        _vm.filterSelectSellersId = $$v;
      },
      expression: "filterSelectSellersId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.outreachStatusConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterSelectStatusId,
      callback: function callback($$v) {
        _vm.filterSelectStatusId = $$v;
      },
      expression: "filterSelectStatusId"
    }
  })], 1)], 1)], 1)])]) : _vm._e(), _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.SITE_LIST_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Add New Site ")])] : _vm._e()], 2)]), _vm.$permissionAbility(_vm.SITE_LIST_SHOW, _vm.permissions) ? _c('vue-good-table', {
    attrs: {
      "style-class": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row2, _props$row3, _props$column2, _props$row4, _props$row5, _props$row5$niche, _props$row5$niche$dat, _props$column3, _props$row6, _props$row6$seller, _props$row6$seller$da, _props$row7, _props$row7$seller, _props$row7$seller$da, _props$column4, _props$row8, _props$row9, _props$column5, _props$row10, _props$row11, _props$column6, _props$row12, _props$row13, _props$column7, _props$row14, _props$row15, _props$row16, _props$row17;
        return [props.column.field === 'serial' ? _c('span', {
          style: {
            display: 'block',
            textAlign: 'center'
          }
        }, [_vm._v(" " + _vm._s(_vm.getSerial(props.index)) + " ")]) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'website_format' ? [props !== null && props !== void 0 && (_props$row = props.row) !== null && _props$row !== void 0 && _props$row.website ? _c('div', [_vm.$permissionAbility(_vm.SITE_LIST_SHOW, _vm.permissions) ? _c('span', {
          on: {
            "click": function click($event) {
              return _vm.onApprove(props.row);
            }
          }
        }, [_c('b', [_vm._v(_vm._s(_vm.formatWebsite(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.website)))])]) : _c('span', [_c('b', [_vm._v(_vm._s(_vm.formatWebsite(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.website)))])])]) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("NA")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'niche_format' ? [props !== null && props !== void 0 && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && _props$row4.niche ? _c('div', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$niche = _props$row5.niche) === null || _props$row5$niche === void 0 ? void 0 : (_props$row5$niche$dat = _props$row5$niche.data) === null || _props$row5$niche$dat === void 0 ? void 0 : _props$row5$niche$dat.name))])]) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("NA")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'seller_format' ? [props !== null && props !== void 0 && (_props$row6 = props.row) !== null && _props$row6 !== void 0 && (_props$row6$seller = _props$row6.seller) !== null && _props$row6$seller !== void 0 && (_props$row6$seller$da = _props$row6$seller.data) !== null && _props$row6$seller$da !== void 0 && _props$row6$seller$da.name ? _c('div', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : (_props$row7$seller = _props$row7.seller) === null || _props$row7$seller === void 0 ? void 0 : (_props$row7$seller$da = _props$row7$seller.data) === null || _props$row7$seller$da === void 0 ? void 0 : _props$row7$seller$da.name))])]) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("NA")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'traffic_format' ? [props !== null && props !== void 0 && (_props$row8 = props.row) !== null && _props$row8 !== void 0 && _props$row8.traffic ? _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatNumber(props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.traffic)))])], 1) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("NA")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'traffic_value_format' ? [props !== null && props !== void 0 && (_props$row10 = props.row) !== null && _props$row10 !== void 0 && _props$row10.traffic_value ? _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v("USD " + _vm._s(_vm.formatNumber(props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.traffic_value)))])], 1) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("NA")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field) === 'spam_score_format' ? [props !== null && props !== void 0 && (_props$row12 = props.row) !== null && _props$row12 !== void 0 && _props$row12.spam_score ? _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.spam_score) + " %")])], 1) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("N/A")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column7 = props.column) === null || _props$column7 === void 0 ? void 0 : _props$column7.field) === 'status_format' ? [_c('b-badge', {
          attrs: {
            "variant": _vm.getStatusVariant(props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.status)
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.status_text) + " ")])] : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.SITE_LIST_SHOW, _vm.permissions) ? _c('span', {
          on: {
            "click": function click($event) {
              return _vm.onApprove(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "EyeIcon",
            "title": "View",
            "size": "16"
          }
        })], 1) : _vm._e(), ((_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.status) !== _vm.rejectedStatusConstants && (_vm.$permissionAbility(_vm.SITE_LIST_EDIT, _vm.permissions) || ((_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : _props$row17.status) !== _vm.approvedStatusConstants && _vm.$permissionAbility(_vm.SITE_LIST_EDIT_BEFORE_APPROVE, _vm.permissions)) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.SITE_LIST_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }], null, false, 113300785)
  }) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-create-form",
      "centered": "",
      "title": _vm.modelType === 'editModel' ? 'Edit Site' : 'Create Site',
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "createFormValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Website",
      "label-for": "website"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "website",
      "vid": "website",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "website",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "The website must be a valid URL. (e.g https://www.example.com)"
          },
          model: {
            value: _vm.websiteUrl,
            callback: function callback($$v) {
              _vm.websiteUrl = $$v;
            },
            expression: "websiteUrl"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Niche",
      "label-for": "niche"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "niche",
      "vid": "niche",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "niche",
            "options": _vm.filteredNicheOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectNicheId,
            callback: function callback($$v) {
              _vm.selectNicheId = $$v;
            },
            expression: "selectNicheId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Seller",
      "label-for": "seller"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "seller",
      "vid": "seller",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "seller",
            "options": _vm.filteredSellerOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectSellerId,
            callback: function callback($$v) {
              _vm.selectSellerId = $$v;
            },
            expression: "selectSellerId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Referral Domain",
      "label-for": "referal_domain"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "referal_domain",
      "vid": "referal_domain"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "referal_domain",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Referral Domain"
          },
          model: {
            value: _vm.referalDomainInput,
            callback: function callback($$v) {
              _vm.referalDomainInput = $$v;
            },
            expression: "referalDomainInput"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Domain Authority",
      "label-for": "domain_authority"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "domain authority",
      "vid": "domain_authority",
      "rules": "numeric|min_value:0|max_value:100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "domain_authority",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Domain Authority"
          },
          model: {
            value: _vm.domainAuthorityInput,
            callback: function callback($$v) {
              _vm.domainAuthorityInput = $$v;
            },
            expression: "domainAuthorityInput"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Traffic",
      "label-for": "traffic"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "traffic",
      "vid": "traffic"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "traffic",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Traffic"
          },
          model: {
            value: _vm.trafficInput,
            callback: function callback($$v) {
              _vm.trafficInput = $$v;
            },
            expression: "trafficInput"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Traffic Value",
      "label-for": "traffic_value"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "traffic_value",
      "vid": "traffic_value"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text",
          attrs: {
            "id": "spamScoreAddon"
          }
        }, [_vm._v("USD")])]), _c('b-form-input', {
          attrs: {
            "id": "traffic_value",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Traffic Value",
            "step": "any"
          },
          model: {
            value: _vm.trafficValueInput,
            callback: function callback($$v) {
              _vm.trafficValueInput = $$v;
            },
            expression: "trafficValueInput"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Spam Score",
      "label-for": "spam_score"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "spam score",
      "vid": "spam_score",
      "rules": "numeric|min_value:0|max_value:100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "spam_score",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Spam Score",
            "aria-describedby": "spamScoreAddon"
          },
          model: {
            value: _vm.spamScoreInput,
            callback: function callback($$v) {
              _vm.spamScoreInput = $$v;
            },
            expression: "spamScoreInput"
          }
        }), _c('div', {
          staticClass: "input-group-append"
        }, [_c('span', {
          staticClass: "input-group-text",
          attrs: {
            "id": "spamScoreAddon"
          }
        }, [_vm._v("%")])])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Remarks",
      "label-for": "remarks"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Remarks",
            "rows": "3"
          },
          model: {
            value: _vm.remarksInput,
            callback: function callback($$v) {
              _vm.remarksInput = $$v;
            },
            expression: "remarksInput"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-1"
  }, [_c('b', [_vm._v("Image Attachment")]), _vm.captionImages.length === 0 ? _c('h6', {
    staticClass: "text-danger text-center"
  }, [_vm._v("No Image Selected")]) : _vm._l(_vm.captionImages, function (form, index) {
    return _c('div', {
      key: index,
      staticClass: "mt-1"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "5",
        "lg": "5",
        "xs": "12"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": 'Caption',
        "label-for": 'caption-' + index
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": 'caption',
        "vid": 'caption-image-' + index,
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref10) {
          var errors = _ref10.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": 'caption-' + index,
              "type": "text",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Caption"
            },
            model: {
              value: form.caption,
              callback: function callback($$v) {
                _vm.$set(form, "caption", $$v);
              },
              expression: "form.caption"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "5",
        "lg": "5",
        "xs": "12"
      }
    }, [_c('b-form-group', {
      class: !form.required ? '' : 'required-label',
      attrs: {
        "label": 'Image',
        "label-for": 'file-' + index
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": 'image',
        "rules": !form.required ? 'size:5048|ext:jpg,jpeg,png' : 'required|size:5048|ext:jpg,jpeg,png',
        "vid": 'image-' + index
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref11) {
          var errors = _ref11.errors;
          return [_c('b-form-file', {
            attrs: {
              "id": 'file-' + index,
              "state": errors.length > 0 ? false : null,
              "placeholder": "Choose a file or drop it here...",
              "drop-placeholder": "Drop file here..."
            },
            on: {
              "change": function change($event) {
                return _vm.handleCaptionImageChange(index);
              }
            },
            model: {
              value: form.file,
              callback: function callback($$v) {
                _vm.$set(form, "file", $$v);
              },
              expression: "form.file"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    }), form.url ? _c('b-link', {
      staticClass: "d-flex align-items-center",
      attrs: {
        "href": form.url,
        "target": "_blank"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": "DownloadIcon"
      }
    }), _vm._v(" View ")], 1) : _vm._e()], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2",
        "lg": "2",
        "xs": "12"
      }
    }, [_c('b-button', {
      staticClass: "mt-2",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeCaptionImage(index);
        }
      }
    }, [_vm._v("X")])], 1)], 1)], 1);
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "10"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.captionImages.length >= 4,
      "variant": "primary"
    },
    on: {
      "click": _vm.addCaptionImage
    }
  }, [_vm._v("Add")])], 1)], 1)], 2)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b', [_vm._v("Image URL")])]), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('div', [_vm.captionHrefs.length === 0 ? _c('h6', {
    staticClass: "text-danger text-center"
  }, [_vm._v("No Image URL Selected")]) : _vm._l(_vm.captionHrefs, function (form, index) {
    return _c('div', {
      key: index,
      staticClass: "mt-1"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "5",
        "lg": "5",
        "xs": "12"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": 'Caption',
        "label-for": 'caption-' + index
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": 'caption',
        "vid": 'caption-href-' + index,
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref12) {
          var errors = _ref12.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": 'caption-' + index,
              "type": "text",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Caption"
            },
            model: {
              value: form.caption,
              callback: function callback($$v) {
                _vm.$set(form, "caption", $$v);
              },
              expression: "form.caption"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "5",
        "lg": "5",
        "xs": "12"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": 'URL',
        "label-for": 'href-' + index
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": 'url',
        "vid": 'href-' + index,
        "rules": "required|url"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref13) {
          var errors = _ref13.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": 'href-' + index,
              "type": "text",
              "state": errors.length > 0 ? false : null,
              "placeholder": "https://viserx.com/media/logo.png"
            },
            model: {
              value: form.href,
              callback: function callback($$v) {
                _vm.$set(form, "href", $$v);
              },
              expression: "form.href"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2",
        "lg": "2",
        "xs": "12"
      }
    }, [_c('b-button', {
      staticClass: "mt-2",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeCaptionHref(index);
        }
      }
    }, [_vm._v("X")])], 1)], 1)], 1);
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "10"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.captionHrefs.length >= 4,
      "variant": "primary"
    },
    on: {
      "click": _vm.addCaptionHref
    }
  }, [_vm._v("Add")])], 1)], 1)], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Approved For",
      "label-for": "approved_for"
    }
  }, [_c('validation-provider', {
    staticClass: "required-label",
    attrs: {
      "name": "approved for",
      "vid": "approved_for",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('v-select', {
          attrs: {
            "id": "approved_for",
            "options": _vm.filteredApprovedNicheOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Here",
            "rules": "required",
            "multiple": ""
          },
          on: {
            "input": _vm.renderNichePricing
          },
          model: {
            value: _vm.approvedForId,
            callback: function callback($$v) {
              _vm.approvedForId = $$v;
            },
            expression: "approvedForId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency",
      "label-for": "traffic_value_currency"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "currency",
      "vid": "traffic_value_currency",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('v-select', {
          attrs: {
            "id": "project",
            "options": _vm.filteredCurrencyOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectedCurrency,
            callback: function callback($$v) {
              _vm.selectedCurrency = $$v;
            },
            expression: "selectedCurrency"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.selectedApprovedNichePricing.length > 0 ? _c('b-card', [_c('h3', {
    staticClass: "my-1"
  }, [_vm._v("Approved for Niche Pricing ")]), _c('b-card-body', _vm._l(_vm.selectedApprovedNichePricing, function (nicheApprove, index) {
    return _c('div', [_c('h5', {
      staticClass: "my-1"
    }, [_vm._v(_vm._s(nicheApprove.name))]), _c('b-row', [_c('b-col', {
      attrs: {
        "md": "6",
        "lg": "6",
        "xs": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Guest Post",
        "label-for": "GuestPost"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Guest Post",
        "vid": "guestPost"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref16) {
          var errors = _ref16.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": "guestPost",
              "type": "number",
              "state": errors.length > 0 ? false : null,
              "value": nicheApprove.guest_post_price,
              "placeholder": "Guest Post",
              "step": "any"
            },
            on: {
              "input": function input(value) {
                return nicheApprove.guest_post_price = value;
              }
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6",
        "lg": "6",
        "xs": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Link Insertion",
        "label-for": "linkInsertion"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Link Insertion",
        "vid": "linkInsertion"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref17) {
          var errors = _ref17.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": "linkInsertion",
              "type": "number",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Link Insertion",
              "value": nicheApprove.link_insertion_price,
              "step": "any"
            },
            on: {
              "input": function input(value) {
                return nicheApprove.link_insertion_price = value;
              }
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1)], 1)], 1);
  }), 0)], 1) : _vm._e(), _vm.isSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "approve-modal",
      "centered": "",
      "title": 'Website Information',
      "hide-footer": "",
      "size": "xl",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenApproveModal
    }
  }, [_c('div', {
    staticClass: "py-1"
  }, [_c('h3', {
    staticStyle: {
      "margin-bottom": "25px"
    }
  }, [_vm._v(" General "), _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": _vm.getStatusVariant((_vm$approveData = _vm.approveData) === null || _vm$approveData === void 0 ? void 0 : _vm$approveData.status)
    }
  }, [_vm._v(_vm._s((_vm$approveData2 = _vm.approveData) === null || _vm$approveData2 === void 0 ? void 0 : _vm$approveData2.status_text))])], 1), _c('div', [_c('span', {
    staticClass: "border-primary border-1 p-1 rounded-lg"
  }, [_vm._v(" " + _vm._s((_this$approveData = this.approveData) === null || _this$approveData === void 0 ? void 0 : _this$approveData.website) + " ")])]), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('h4', {
    staticClass: "text-center font-weight-bold"
  }, [_vm._v("Domain Authority")]), _c('div', [_vm.enableWidget ? [_c('vue-apex-charts', {
    attrs: {
      "type": "donut",
      "height": "350",
      "options": _vm.donutChart.chartOptions,
      "series": _vm.donutChart.series
    }
  })] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('b', [_vm._v("Traffic (Monthly)")]), _c('h1', [_vm._v(_vm._s(_vm.formatNumber((_this$approveData2 = this.approveData) === null || _this$approveData2 === void 0 ? void 0 : _this$approveData2.traffic)))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b', [_vm._v("Traffic Value")]), _c('div', [_c('h2', [_vm._v("USD " + _vm._s(_vm.formatNumber((_this$approveData3 = this.approveData) === null || _this$approveData3 === void 0 ? void 0 : _this$approveData3.traffic_value)))])])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._l((_this$approveData4 = this.approveData) === null || _this$approveData4 === void 0 ? void 0 : _this$approveData4.approveforNiches, function (approveFor, index) {
    var _approveFor$pivot, _approveFor$pivot2, _vm$approveData3, _vm$approveData3$curr, _approveFor$pivot3, _approveFor$pivot4, _approveFor$pivot5, _vm$approveData4, _vm$approveData4$curr, _approveFor$pivot6;
    return [_c('div', {
      staticClass: "my-1"
    }, [_c('h3', [_c('b-badge', {
      staticClass: "w-100",
      staticStyle: {
        "padding": "10px"
      },
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(approveFor === null || approveFor === void 0 ? void 0 : approveFor.name))])], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b', [_vm._v("Guest Price")]), _c('h3', [_vm._v(" " + _vm._s((_approveFor$pivot = approveFor.pivot) !== null && _approveFor$pivot !== void 0 && _approveFor$pivot.guest_post_pricing && ((_approveFor$pivot2 = approveFor.pivot) === null || _approveFor$pivot2 === void 0 ? void 0 : _approveFor$pivot2.guest_post_pricing) !== 0 ? ((_vm$approveData3 = _vm.approveData) === null || _vm$approveData3 === void 0 ? void 0 : (_vm$approveData3$curr = _vm$approveData3.currency) === null || _vm$approveData3$curr === void 0 ? void 0 : _vm$approveData3$curr.currency) + ' ' + ((_approveFor$pivot3 = approveFor.pivot) === null || _approveFor$pivot3 === void 0 ? void 0 : _approveFor$pivot3.guest_post_pricing) : 'N/A') + " ")])]), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b', [_vm._v("Link Price")]), _c('h3', [_vm._v(" " + _vm._s((_approveFor$pivot4 = approveFor.pivot) !== null && _approveFor$pivot4 !== void 0 && _approveFor$pivot4.link_insertion_pricing && ((_approveFor$pivot5 = approveFor.pivot) === null || _approveFor$pivot5 === void 0 ? void 0 : _approveFor$pivot5.link_insertion_pricing) !== 0 ? ((_vm$approveData4 = _vm.approveData) === null || _vm$approveData4 === void 0 ? void 0 : (_vm$approveData4$curr = _vm$approveData4.currency) === null || _vm$approveData4$curr === void 0 ? void 0 : _vm$approveData4$curr.currency) + ' ' + ((_approveFor$pivot6 = approveFor.pivot) === null || _approveFor$pivot6 === void 0 ? void 0 : _approveFor$pivot6.link_insertion_pricing) : 'N/A') + " ")])])], 1)], 1)];
  })], 2)], 1), _c('p', {
    staticClass: "fs-5"
  }, [_c('b', [_vm._v("Notes:")]), _vm._v(" " + _vm._s((_vm$approveData5 = _vm.approveData) === null || _vm$approveData5 === void 0 ? void 0 : _vm$approveData5.remarks))]), _vm.$permissionAbility(_vm.SITE_LIST_REVIEW, _vm.permissions) ? [_c('h5', [_vm._v("Reviewer Remarks")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "remarks"
    }
  }, [_c('b-form-textarea', {
    staticClass: "mb-1",
    attrs: {
      "id": "remarks",
      "placeholder": "Remarks",
      "rows": "3",
      "disabled": ((_vm$approveData6 = _vm.approveData) === null || _vm$approveData6 === void 0 ? void 0 : _vm$approveData6.status) !== _vm.pendingStatusConstants && ((_vm$approveData7 = _vm.approveData) === null || _vm$approveData7 === void 0 ? void 0 : _vm$approveData7.status) !== _vm.softRejectedStatusConstants
    },
    model: {
      value: _vm.reviewerRemarks,
      callback: function callback($$v) {
        _vm.reviewerRemarks = $$v;
      },
      expression: "reviewerRemarks"
    }
  })], 1), ((_vm$approveData8 = _vm.approveData) === null || _vm$approveData8 === void 0 ? void 0 : _vm$approveData8.status) === _vm.pendingStatusConstants || ((_vm$approveData9 = _vm.approveData) === null || _vm$approveData9 === void 0 ? void 0 : _vm$approveData9.status) === _vm.softRejectedStatusConstants ? [_c('div', {
    staticClass: "mb-1"
  }, [_vm.isSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right ml-2",
    attrs: {
      "type": "submit",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.statusSubmit(_vm.rejectedStatusConstants);
      }
    }
  }, [_vm._v(" Reject ")]), ((_vm$approveData10 = _vm.approveData) === null || _vm$approveData10 === void 0 ? void 0 : _vm$approveData10.status) !== _vm.softRejectedStatusConstants ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right ml-2",
    attrs: {
      "type": "submit",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.statusSubmit(_vm.softRejectedStatusConstants);
      }
    }
  }, [_vm._v(" Soft Reject ")]) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.statusSubmit(_vm.approvedStatusConstants);
      }
    }
  }, [_vm._v(" Approve ")])]], 2)] : _vm._e()], 2)] : _vm._e(), ((_vm$approveData11 = _vm.approveData) === null || _vm$approveData11 === void 0 ? void 0 : _vm$approveData11.href_captions.length) > 0 ? [_c('br'), _c('br'), _c('br'), _c('br'), _c('b-badge', {
    staticClass: "mb-1 p-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("Image URL")]), _c('b-row', {
    staticClass: "mb-1 mt-1"
  }, _vm._l((_vm$approveData12 = _vm.approveData) === null || _vm$approveData12 === void 0 ? void 0 : _vm$approveData12.href_captions, function (href, index) {
    return _c('b-col', {
      key: index,
      staticClass: "mb-1",
      attrs: {
        "lg": "3",
        "sm": "6"
      }
    }, [_c('b-link', {
      staticClass: "d-flex align-items-center",
      attrs: {
        "href": href.href,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(href.caption) + " ")])], 1);
  }), 1)] : _vm._e(), ((_vm$approveData13 = _vm.approveData) === null || _vm$approveData13 === void 0 ? void 0 : _vm$approveData13.image.length) > 0 ? [_c('div', [_c('h5', [_c('b-badge', {
    staticClass: "mb-1 p-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("Image Attachments")])], 1), _vm._l((_vm$approveData14 = _vm.approveData) === null || _vm$approveData14 === void 0 ? void 0 : _vm$approveData14.image, function (image, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "mb-5"
    }, [_c('h4', [_vm._v(_vm._s(image.caption))]), _c('b-link', {
      attrs: {
        "href": image.url,
        "target": "_blank"
      }
    }, [_c('b-img', {
      staticClass: "w-100",
      attrs: {
        "src": image.url
      }
    })], 1)], 1)]);
  })], 2)] : _vm._e()], 2)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }